import { useState } from 'react'

export default function useToken() {
    function getToken(){
        const tokenString = sessionStorage.getItem('token')

        return tokenString
    }

    const [token, setToken] = useState(getToken())

    const saveToken = userToken => {
        sessionStorage.setItem('token', JSON.stringify(userToken))
        setToken(userToken)
    }

    return {
        setToken: saveToken,
        token
    }
}